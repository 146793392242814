import React from "react";
import './Header.css';
import logo from './Logo.png';

const Header = () => {
    return (
        <header className="header">
            <img src={logo} alt="WHV Jobs Logo" className="header-logo" />
        </header>
    );
};

export default Header;