import React from "react";
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>&copy; 2024 WHV Reviews</p>
                <p>Contact: whvreviews@gmail.com</p>
            </div>
        </footer>
    );
};

export default Footer;