import React, { useState, useEffect, useRef } from "react";
import './Home.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { getWorkplaces, addNewWorkplace, addReview, getCoordinatesService, getAddressSuggestions } from '../../api/workplaceService';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const defaultIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const highlightedIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const Home = () => {
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [workplaces, setWorkplaces] = useState([]);
    const markerRefs = useRef([]);
    const [selectedWorkplace, setSelectedWorkplace] = useState(null);
    const [selectedMarkerId, setSelectedMarkerId] = useState(null);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [newReviewText, setNewReviewText] = useState("");
    const [newReviewRating, setNewReviewRating] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const inputRef = useRef(null);
    const suggestionsRef = useRef(null);

    // New workplace
    const [showAddWorkplaceForm, setShowAddWorkplaceForm] = useState(false);
    const [newWorkplaceName, setNewWorkplaceName] = useState('');
    const [newWorkplaceLocation, setNewWorkplaceLocation] = useState('');
    const [newWorkplaceCategory, setNewWorkplaceCategory] = useState('');
    const [newWorkplaceReview, setNewWorkplaceReview] = useState("")
    const [newWorkplaceRating, setNewWorkplaceRating] = useState(null)

    // Location suggestions
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);


    const fetchWorkplaces = async () => {
        try {
            setIsLoading(true);
            const data = await getWorkplaces();
            setWorkplaces(data);
        } catch (error) {
            //console.error("Failed to fetch workplaces:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchWorkplaces();
    }, []);

    const toggleReview = () => {
        setIsExpanded(!isExpanded);
    };

    const handleNext = () => {
        setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % selectedWorkplace.reviews.length);
    };

    const handlePrevious = () => {
        setCurrentReviewIndex(
            (prevIndex) => (prevIndex - 1 + selectedWorkplace.reviews.length) % selectedWorkplace.reviews.length
        );
    };

    const nullifyNewReview = () => {
        setShowReviewForm(false);
        setNewReviewRating(null);
        setNewReviewText("");
    };

    const nullifyNewWorkplace = () => {
        setShowAddWorkplaceForm(false);
        setNewWorkplaceName("");
        setNewWorkplaceLocation("");
        setNewWorkplaceCategory("");
        setNewWorkplaceReview("");
        setNewWorkplaceRating(null);
        setQuery("");
    };

    const handleSubmitReview = async () => {
        if (!newReviewRating || isNaN(newReviewRating) || newReviewRating < 1 || newReviewRating > 5) {
            setErrorMessage('Rating cannot be empty. Please select a star rating.');
            setShowError(true);

            setTimeout(() => {
                setShowError(false);
            }, 3000);

            setTimeout(() => {
                setErrorMessage('');
            }, 3500);
            return;
        }

        setErrorMessage('');
        setShowError(false);

        try {
            const reviewData = {
                review: newReviewText.trim(),
                rating: parseInt(newReviewRating),
            };

            const updatedWorkplace = await addReview(selectedWorkplace._id, reviewData);
            setSelectedWorkplace(updatedWorkplace);
            nullifyNewReview();
        } catch (error) {
            //console.error("Error submitting review:", error);
        }
    };

    const getCoordinates = async (address) => {
        try {
            const response = await getCoordinatesService(address);

            if (response.features.length > 0) {
                const { center } = response.features[0];
                const [lng, lat] = center;
                return { lat, lng };
            } else {
                //console.error('No results found for the given address.');
                return null;
            }
        } catch (error) {
            //console.error('Error fetching coordinates:', error);
            return null;
        }
    };

    const handleAddWorkplaceSubmit = async (e) => {
        e.preventDefault();

        if (!newWorkplaceRating || isNaN(newWorkplaceRating) || newWorkplaceRating < 1 || newWorkplaceRating > 5) {
            setErrorMessage('Rating cannot be empty. Please select a star rating.');
            setShowError(true);

            setTimeout(() => {
                setShowError(false);
            }, 3000);

            setTimeout(() => {
                setErrorMessage('');
            }, 3500);
            return;
        }

        setErrorMessage('');
        setShowError(false);

        try {
            const coordinates = await getCoordinates(query);

            const isInAustralia = (
                coordinates.lat >= -44.0 && coordinates.lat <= -10.0 &&
                coordinates.lng >= 113.0 && coordinates.lng <= 154.0
            );

            if (!isInAustralia) {
                setErrorMessage('The workplace must be located in Australia.');
                setShowError(true);

                setTimeout(() => {
                    setShowError(false);
                }, 3000);

                setTimeout(() => {
                    setErrorMessage('');
                }, 3500);
                return;
            }

            const newWorkplaceData = {
                name: newWorkplaceName,
                location: query,
                category: newWorkplaceCategory,
                lat: coordinates.lat,
                lon: coordinates.lng,
                ratings: 1,
                rating: newWorkplaceRating,
                reviews: [{
                    rating: newWorkplaceRating,
                    review: newWorkplaceReview
                }]
            };

            const newWorkplace = await addNewWorkplace(newWorkplaceData);
            nullifyNewWorkplace();
            fetchWorkplaces();
        } catch (error) {
            //console.error("Error submitting review:", error);
        }

    };

    const handleInputChange = async (e) => {
        const input = e.target.value;
        setQuery(input);

        if (query.length < 3) {
            setSuggestions([]);
            return;
        }

        try {
            const response = await getAddressSuggestions(query);
            setSuggestions(response.features);
            return;
        } catch (error) {
            //console.error("There was an error while fetching places:", error);
        }
    };

    const handleSuggestionClick = async (suggestion) => {
        try {
            setQuery(suggestion.place_name);
            setSuggestions([]);
            return;
        } catch (error) {
            //console.error("There was an error picking a suggestion:", error);
        }
    };

    const handleClickOutside = (e) => {
        if (
            inputRef.current &&
            !inputRef.current.contains(e.target) &&
            suggestionsRef.current &&
            !suggestionsRef.current.contains(e.target)
        ) {
            setSuggestions([]);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="home">
            {isLoading && (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="intro-box">
                <h2>G'Day Y'all and Welcome to WHV Reviews</h2>
                <p>
                    As a working holiday visa holder in Australia, it’s essential to have accurate information about your future workplace
                    and the working conditions you’ll be facing. Knowing these details in advance can help you make informed decisions
                    and ensure a smoother experience during your stay.
                </p>
                <p>
                Feel free to share your thoughts in your reviews, whether it's about salary, workplace conditions, or management. 
                Our goal is to build a community that fosters maximum transparency. 
                <br/>By sharing experiences and reviews of workplaces, we can all contribute to creating a safer and more informed network for everyone.
                <br/>Stay informed, stay safe, and enjoy your time in Australia!
                </p>
                
            </div>
            <div className="content-container">
                <div className="workplace-box">
                    {selectedWorkplace ? (
                        <>
                            <div className="workplace-info">
                                <h3>{selectedWorkplace.name}</h3>
                                <p>{selectedWorkplace.location}</p>
                                <p>{selectedWorkplace.category}</p>
                                <p className="rating-text">★ {selectedWorkplace.rating}</p>
                            </div>
                            <div className="review-box-container">
                                <div className="review-box">
                                    <button className="arrow-button left" onClick={handlePrevious}>
                                        &#8249;
                                    </button>
                                    <div className="review-content">
                                        <div className="rating-stars">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                                <span
                                                    key={star}
                                                    className={`star ${star <= selectedWorkplace.reviews[currentReviewIndex]?.rating ? 'filled' : ''}`}
                                                >
                                                    ★
                                                </span>
                                            ))}
                                        </div>
                                        <p className={'review-text'}>
                                            {selectedWorkplace.reviews[currentReviewIndex]?.review}
                                        </p>
                                        <button onClick={toggleReview}>
                                            Show More Reviews
                                        </button>
                                    </div>
                                    <button className="arrow-button right" onClick={handleNext}>
                                        &#8250;
                                    </button>
                                </div>
                            </div>
                            <div>
                                {isExpanded && (
                                    <div className="reviews-modal-overlay" onClick={toggleReview}>
                                        <div
                                            className="reviews-modal-content"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <h2>{selectedWorkplace.name}</h2>
                                            <p>★ {selectedWorkplace.rating}</p>
                                            <p>{selectedWorkplace.reviews.length} Reviews</p>
                                            <button className="reviews-modal-close-button" onClick={toggleReview}>
                                                &times;
                                            </button>
                                            <ul className="reviews-list">
                                                {selectedWorkplace.reviews.map((review, index) => (
                                                    <li key={index} className="review-item">
                                                        <div className="rating-stars">
                                                            {[1, 2, 3, 4, 5].map((star) => (
                                                                <span
                                                                    key={star}
                                                                    className={`star ${star <= review.rating ? 'filled' : ''}`}
                                                                >
                                                                    ★
                                                                </span>
                                                            ))}
                                                        </div>
                                                        <br />
                                                        {review.review}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="add-review-container">
                                <button className="add-review-button" onClick={() => setShowReviewForm(true)}>
                                    Add a Review
                                </button>
                            </div>
                            {showReviewForm && (
                                <div className="modal-overlay" onClick={() => nullifyNewReview()}>
                                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                                        {errorMessage && (
                                            <div className={`error-message ${!showError ? 'hidden' : ''}`}>
                                                {errorMessage}
                                            </div>
                                        )}
                                        <h2>Add a Review</h2>
                                        <div className="rating-stars-picker">
                                            {[1, 2, 3, 4, 5].map((star) => (
                                                <span
                                                    key={star}
                                                    className={`star ${star <= newReviewRating ? 'filled' : ''}`}
                                                    onClick={() => setNewReviewRating(star)}
                                                >
                                                    ★
                                                </span>
                                            ))}
                                        </div>
                                        <textarea
                                            placeholder="Write your review here..."
                                            value={newReviewText}
                                            onChange={(e) => setNewReviewText(e.target.value)}
                                        />
                                        <div className="modal-actions">
                                            <button onClick={handleSubmitReview}>Submit</button>
                                            <button onClick={() => nullifyNewReview()}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="placeholder">
                            Select a marker to see details
                        </div>
                    )}
                </div>

                <MapContainer center={[-25.2744, 133.7751]} zoom={4} className="map-container">
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/">CARTO</a>'
                    />
                    <button
                        className="add-workplace-button"
                        onClick={() => setShowAddWorkplaceForm(true)}>
                        Add New Workplace
                    </button>
                    {workplaces.map((workplace, index) => {
                        if (!markerRefs.current[index]) {
                            markerRefs.current[index] = React.createRef();
                        }

                        return (
                            <Marker
                                key={workplace._id}
                                position={[workplace.lat, workplace.lon]}
                                ref={markerRefs.current[index]} // Attach the ref to the Marker
                                icon={selectedMarkerId === workplace._id ? highlightedIcon : defaultIcon}
                                eventHandlers={{
                                    click: () => {
                                        setSelectedWorkplace(workplace); // Update selected workplace on marker click
                                        setSelectedMarkerId(workplace._id);
                                    },
                                    mouseover: () => {
                                        if (markerRefs.current[index].current) {
                                            markerRefs.current[index].current.openPopup(); // Open the popup programmatically
                                        }
                                    },
                                    mouseout: () => {
                                        if (markerRefs.current[index].current) {
                                            markerRefs.current[index].current.closePopup(); // Close the popup programmatically
                                        }
                                    },
                                }}
                            >
                                <Popup>
                                    <strong>{workplace.name}</strong>
                                    <br />
                                    {workplace.location}
                                    <br />
                                    Rating: {workplace.rating}
                                </Popup>
                            </Marker>
                        );
                    })}
                </MapContainer>
                {showAddWorkplaceForm && (
                    <div className="new-workplace-modal-overlay" onClick={(e) => nullifyNewWorkplace()}>
                        <div className="new-workplace-modal" onClick={(e) => e.stopPropagation()}>
                            {errorMessage && (
                                <div className={`error-message ${!showError ? 'hidden' : ''}`}>
                                    {errorMessage}
                                </div>
                            )}
                            <h2>Add New Workplace</h2>
                            <form onSubmit={handleAddWorkplaceSubmit}>

                                <div className="form-group">
                                    <label htmlFor="name">Workplace Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={newWorkplaceName}
                                        onChange={(e) => setNewWorkplaceName(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="location">Location</label>
                                    <input
                                        type="text"
                                        ref={inputRef}
                                        value={query}
                                        onChange={handleInputChange}
                                        onClick={handleInputChange}
                                        className="address-input"
                                        required
                                    />
                                    {suggestions.length > 0 && (
                                        <ul className="suggestions-list" ref={suggestionsRef}>
                                            {suggestions.map((suggestion, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => handleSuggestionClick(suggestion)}
                                                    className="suggestion-item"
                                                >
                                                    {suggestion.place_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    {selectedAddress && <p>Selected Address: {selectedAddress}</p>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="category">Category</label>
                                    <select
                                        id="category"
                                        value={newWorkplaceCategory}
                                        onChange={(e) => setNewWorkplaceCategory(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled>Select a category</option>
                                        <option value="Agriculture">Agriculture</option>
                                        <option value="Energy">Energy</option>
                                        <option value="Manufacturing">Manufacturing</option>
                                        <option value="Hospitality">Hospitality</option>
                                        <option value="Construction">Construction</option>
                                        <option value="Tourism">Tourism</option>
                                        <option value="Mining">Mining</option>
                                        <option value="Volunteering">Volunteering</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="review">Review & Rating</label>
                                    <textarea
                                        id="review"
                                        value={newWorkplaceReview}
                                        placeholder="Write your review here..."
                                        onChange={(e) => setNewWorkplaceReview(e.target.value)}
                                        rows="3"
                                    />
                                </div>

                                <div className="form-group">
                                    <div className="rating-stars-picker">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <span
                                                key={star}
                                                className={`star ${star <= newWorkplaceRating ? 'filled' : ''}`}
                                                onClick={() => setNewWorkplaceRating(star)}
                                            >
                                                ★
                                            </span>
                                        ))}
                                    </div>
                                </div>

                                <button type="submit">Submit</button>
                                <button type="button" onClick={() => nullifyNewWorkplace()}>
                                    Cancel
                                </button>
                            </form>

                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Home;